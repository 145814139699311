export function pdfThumbnail(
  pdfUrl: string,
  target: 'feed' | 'rfq' | 'feed-extract',
): string {
  if (target === 'rfq') {
    return pdfUrl
      .replace('.pdf', '.png')
      .replace('image/upload/', `image/upload/c_pad,g_center,w_420,h_199/`);
  }
  // extract objects from image, it can be an array, example of usage: prompt_(part;3d drawing;...)
  if (target === 'feed-extract') {
    return pdfUrl
      .replace('.pdf', '.png')
      .replace(
        'image/upload/',
        `image/upload/c_fill,g_center,w_600,h_320/e_extract:prompt_(part);multiple_true/`,
      );
  }
  return pdfUrl
    .replace('.pdf', '.png')
    .replace('image/upload/', `image/upload/c_fill,g_center,w_600,h_320/`);
}
