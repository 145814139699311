export enum TrackerEvents {
  SEARCH_PAGE_LOAD = 'SEARCH - Page load',
  SEARCH_MAN_WEBSITE_CLICK = 'SEARCH - "Website" in search result MAN card clicked by user',
  SEARCH_MAN_PHONE_CLICK = 'SEARCH - "Phone" in search result MAN card clicked by user',
  SEARCH_MAN_EMAIL_CLICK = 'SEARCH - "Mail" in search result MAN card clicked by user',
  SEARCH_MAN_ADD_BOOKMARK = 'SEARCH - Add bookmark',
  SEARCH_MAN_REMOVE_BOOKMARK = 'SEARCH - Remove bookmark',
  SEARCH_MAN_PRESENTATION_CLICK = 'SEARCH - "Download Presentation" clicked by user',
  SEARCH_UPLOAD_FILE = 'SEARCH - Files uploaded',
  SEARCH_QUERY = 'SEARCH - Search query sent v2',
  SEARCH_3D_VIEW_USE = 'SEARCH - Interaction with file 3D preview',
  SEARCH_MEDIA_CLICK = 'SEARCH - Media click',
  SEARCH_PRICE_CURVE_INTERACTION = 'SEARCH - Price curve interaction',
  SEARCH_DEMO_PART_INTERACTION = 'SEARCH - Demo part interaction',
  SEARCH_DEMO_PART_CLICK = 'SEARCH - Demo part click',
  CREATE_RFQ_REQUEST_INIT = 'SEARCH - Create request - initialize',
  CREATE_RFQ_REQUEST_PART_1 = 'SEARCH - Create request - Part 1',
  CREATE_RFQ_REQUEST_PART_2 = 'SEARCH - Create request - Part 2',
  CREATE_RFQ_REQUEST_PART_3 = 'SEARCH - Create request - Part 3',
  CREATE_RFQ_REQUEST_PART_4 = 'SEARCH - Create request - Part 4',
  CREATE_RFQ_REQUEST_MOVE_FILES = 'SEARCH - Create request - move files',
  CREATE_RFQ_REQUEST_POPULATE_ALL_CLICK = 'SEARCH - Create request - Populate all click',
  CREATE_RFQ_REQUEST_POPULATE_ALL_DONE = 'SEARCH - Create request - Populate all done',
  CREATE_RFQ_REQUEST_PUBLISH = 'SEARCH - Create request - Published',
  CREATE_RFQ_REQUEST_REPUBLISH = 'Create RFQ - Republished RFQ published',
  RFQ_PAGE_LOAD = 'RFQPage - Page load',
  RFQ_CAROUSEL_CLICK = 'RFQPage - Carousel click',
  RFQ_ADDITIONAL_FILE_CLICK = 'RFQPage - Additional file click',
  RFQ_WORKPIECE_FILE_CLICK = 'RFQPage - Workpiece file click',
  RFQ_CONTACT_INTERESTED = 'RFQPage - User clicked INTERESTED',
  RFQ_CONTACT_REJECTED = 'RFQPage - User clicked REJECTED',
  RFQ_CONTACT_REQUEST_ACCESS = 'RFQPage - User clicked REQUEST ACCESS',
  RFQ_CONTACT_BUYER_CLICK = 'RFQPage - Contact buyer click',
  RFQ_BUYER_WEBSITE_CLICK = 'RFQPage - Buyer website click',
  RFQ_BUYER_EMAIL_CLICK = 'RFQPage - Buyer email click',
  RFQ_BUYER_PHONE_CLICK = 'RFQPage - Buyer phone click',
  RFQ_USER_REGISTERED = 'RFQPage - User registered',
  RFQ_DETAILS_BOOKMARK = 'Request details - Bookmark',
  RFQ_DETAILS_UNBOOKMARK = 'Request details - Unbookmark',
  YOUR_VISIBILITY_PAGE_LOAD = 'Your Visibility - Page loaded',
  YOUR_VISIBILITY_UNLOCK_CLICK = 'Your Visibility - Unlock click',
  YOUR_VISIBILITY_CONTACT_DETAIL_CLICK = 'Your Visibility - Contact detail click',
  YOUR_VISIBILITY_CONTACT_DETAIL_UPDATE = 'Your Visibility - Contact detail update',
  YOUR_VISIBILITY_UPDATE_CAPABILITIES_CLICK = 'Your Visibility - Update capabilities click',
  YOUR_VISIBILITY_CAPACITY_CLICK = 'Your Visibility - Capacity click',
  YOUR_VISIBILITY_LOGO_UPLOAD_CLICK = 'Your Visibility - Logo upload click',
  YOUR_VISIBILITY_VERIFICATION_CLICK = 'Your Visibility - Verification start click',
  YOUR_VISIBILITY_PDF_UPLOAD_CLICK = 'Your Visibility - PDF upload click',
  YOUR_VISIBILITY_PDF_REMOVE_CLICK = 'Your Visibility - PDF remove click',
  YOUR_VISIBILITY_VIDEO_UPLOAD_CLICK = 'Your Visibility - Video upload click',
  YOUR_VISIBILITY_VIDEO_REMOVE_CLICK = 'Your Visibility - Video remove click',
  YOUR_VISIBILITY_CAPABILITIES_SAVED = 'Your Visibility - Capabilities saved',
  YOUR_VISIBILITY_MANAGE_MACHINE_PARK_CLICKED = 'Your Visibility - Manage machine park clicked',
  PRICING_OVERVIEW_PAGE_LOAD = 'Pricing Overview - Page loaded',
  PRICING_OVERVIEW_REGISTER_CTA_CLICK = 'Pricing Overview - Registration CTA clicked',
  PRICING_OVERVIEW_FREE_CTA_CLICK = 'Pricing Overview - Free CTA clicked',
  PRICING_OVERVIEW_T0_CTA_CLICK = 'Pricing Overview - Tier0 CTA clicked',
  PRICING_OVERVIEW_T2_CTA_CLICK = 'Pricing Overview - Tier2 CTA clicked',
  PRICING_OVERVIEW_T3_CTA_CLICK = 'Pricing Overview - Tier3 CTA clicked',
  PRICING_OVERVIEW_PREVIEW_CLICK = 'Pricing Overview - Preview clicked',
  STRIPE_CHECKOUT_VIEWED = 'Stripe Checkout - viewed',
  STRIPE_CHECKOUT_SUCCESS_VIEWED = 'Stripe Checkout - Success page viewed',
  FEED_PAGE_LOAD = 'Feed - Page loaded',
  FEED_RFQ_CTA_LOGIN_WALL_CLICKED = 'Feed - RFQ CTA login wall clicked',
  FEED_RFQ_CTA_PAYWALL_CLICKED = 'Feed - RFQ CTA paywall clicked',
  FEED_RFQ_CTA_CLICKED = 'Feed - RFQ CTA clicked',
  FEED_PAYWALL_PRICING_CLICK = 'Feed - Paywall pricing clicked',
  FEED_PAYWALL_DISMISSED = 'Feed - Paywall dismissed',
  FEED_AI_MATCHING_CLICK = 'Feed - AI matching click',
  FEED_AI_MATCHING_APPLIED = 'Feed - AI matching applied',
  FEED_QUERY_SENT = 'Feed - Query sent',
  FEED_SORT_AND_FILTER_CLICKED = 'Feed - Sort and filter clicked',
  GENERAL_PAGE_LOAD = 'Partfox - General Page Load',
  MY_REQUESTS_PAGE_LOAD = 'My requests - Page loaded',
  MY_REQUESTS_REPUBLISH_INIT = 'My Requests - Republish RFQ clicked',
  MY_REQUESTS_START_NEW_RFQ = 'My Requests - Start new Request clicked',
  MY_REQUESTS_CLOSE_REQUEST = 'My Requests - Close Request clicked',
  MY_REQUESTS_REQUEST_CLOSED = 'My Requests - Request closed',
  MY_REQUESTS_EDIT_REQUEST = 'My Requests - Edit Request clicked',
  MY_REQUESTS_REQUEST_UPDATED = 'My Requests - Request Updated',
  MY_REQUESTS_CHANGE_DEADLINE_CLICKED = 'My Requests - Change Deadline Clicked',
  MY_REQUESTS_CHANGE_DEADLINE_CHANGED = 'My Requests - Change Deadline Changed',
  REQUEST_INVITATIONS_PAGE_LOAD = 'Request invitations - Page loaded',
  FEATURED_REQUESTS_PAGE_LOAD = 'Featured requests - Page loaded',
  FEATURED_REQUESTS_UNBOOKMARK = 'Featured requests - Unbookmark',
  SETTINGS_PAGE_LOAD = 'Settings - Page load',
  SETTINGS_PERSONAL_INFORMATION_UPDATED = 'Settings - Personal information updated',
  SETTINGS_NEW_EMAIL_ADDRESS_SAVED = 'Settings - New email address saved',
  SETTINGS_NEW_PASSWORD_SAVED = 'Settings - New password saved',
  SETTINGS_COMPANY_DETAILS_UPDATED = 'Settings - Company details updated',
  SETTINGS_NOTIFICATIONS_SETTINGS_CHANGED = 'Settings - Changed Subscription settings',
  SETTINGS_UPGRADE_PLAN_CLICKED = 'Settings - Upgrade plan clicked',
  SETTINGS_CANCEL_SUBSCRIPTION_CLICKED = 'Settings - Cancel Subscription clicked',
  SETTINGS_SUBSCRIPTION_CANCELLED = 'Settings - Subscription cancelled',
  SETTINGS_SUBSCRIPTION_RESUBSCRIBED = 'Settings - Subscription resubscribed',
  SETTINGS_MANAGE_PAYMENTS_CLICKED = 'Settings - Manage payments & invoices clicked',
  SETTINGS_TEAMMATE_DELETED = 'Settings - Teammate deleted',
  SETTINGS_TEAMMATE_INVITED = 'Settings - Teammate invited',
  ONBOARDING_STEP_1_VIEWED = 'Onboarding - Step 1 viewed',
  ONBOARDING_STEP_2_VIEWED = 'Onboarding - Step 2 viewed',
  ONBOARDING_STEP_2_FINISHED = 'Onboarding - Step 2 Finished',
  REGISTRATION_VIEW_ROLE_SELECTION = 'Registration - View role selection',
  REGISTRATION_VIEW_STEP_1 = 'Registration - View step 1',
  REGISTRATION_VIEW_STEP_2 = 'Registration - View step 2',
  REGISTRATION_VIEW_STEP_3 = 'Registration - View step 3',
  REGISTRATION_CLICK_SUBMIT = 'Registration - Click submit',
  REGISTRATION_CLICK_CHANGE_ROLE = 'Registration - Click change role',
  REGISTRATION_VIEW_SUCCESS_PAGE = 'Registration - View success page',
  REGISTRATION_VIEW_JOIN_SUCCESS_PAGE = 'Registration - View join success page',
  LOGIN_SIGNUP_CLICK = 'Login - Click Signup',
  MY_MACHINERY_PAGE_LOAD = 'My Machinery - Page Load',
  MY_MACHINERY_EDIT_MACHINE_CLICKED = 'My Machinery - Edit machine clicked',
  MY_MACHINERY_MACHINE_UPDATED = 'My Machinery - Machine updated',
  MY_MACHINERY_HIDE_MACHINE_CLICKED = 'My Machinery - Hide machine clicked',
  MY_MACHINERY_MACHINE_HIDDEN = 'My Machinery - Machine hidden',
  MY_MACHINERY_MACHINE_INDEXED = 'My Machinery - Machine indexed',
  MY_MACHINERY_DELETE_MACHINE_CLICKED = 'My Machinery - Delete machine clicked',
  MY_MACHINERY_MACHINE_DELETED = 'My Machinery - Machine deleted',
}

export default TrackerEvents;
