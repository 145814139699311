import { lazy, Suspense } from 'react';
import App from '@/App';
import { createBrowserRouter, redirect } from 'react-router-dom';

import ErrorPage from '@/core/components/layout/error-page';
import { PageLoader } from '@/core/components/layout/page-loader';
import UserAuthenticatedContext from '@/core/context/UserAuthenticatedContext';
import { AuthorizationGuard } from '@/core/guards/authorization-guard';
import { FeedProvider } from '@/modules/feeds/context/feed-context';
import { FileDropProvider } from '@/modules/matchmaking/context/file-drop-context';
import { SearchProvider } from '@/modules/matchmaking/context/SearchContext';
import { RFQContextProvider } from '@/modules/offering/context/RFQContext';
import { CheckoutContextProvider } from '@/modules/payment/context/checkout-context';
import { RFQCreationProvider } from '@/modules/rfq-creation/context/rfq-creation-context';
import { User } from '@/generated/api';

const ChangeEmailConfirmation = lazy(
  () => import('@/modules/user-management/change-email-confirmation'),
);
const VerifyEmailConfirmation = lazy(
  () => import('@/modules/user-management/verify-email-confirmation'),
);
const ResetPassword = lazy(
  () => import('@/modules/user-management/reset-password'),
);
const SettingNotificationsSettings = lazy(
  () => import('@/modules/user-management/public-notifications-settings'),
);
const UserSettings = lazy(
  () => import('@/modules/user-management/user-settings'),
);
const SettingProfile = lazy(
  () => import('@/modules/user-management/components/setting-profile'),
);
const SettingCompany = lazy(
  () => import('@/modules/user-management/components/setting-company'),
);
const SettingNotifications = lazy(
  () => import('@/modules/user-management/components/setting-notifications'),
);
const SettingSecurity = lazy(
  () => import('@/modules/user-management/components/setting-security'),
);
const SettingSubscription = lazy(
  () => import('@/modules/user-management/components/setting-subscription'),
);
const SettingTeam = lazy(
  () => import('@/modules/user-management/components/setting-team'),
);
const ProjectNotFound = lazy(() => import('@/modules/offering/rfq-not-found'));
const RFQSummary = lazy(() => import('@/modules/offering/rfq-summary'));
const RFQEditPage = lazy(() => import('@/modules/offering/rfq-edit-page'));
const MyProjects = lazy(() => import('@/modules/projects/my-projects'));
const FeaturedRequests = lazy(
  () => import('@/modules/projects/featured-requests'),
);
const ProjectInvitations = lazy(
  () => import('@/modules/projects/project-invitations'),
);
const Search = lazy(() => import('@/modules/matchmaking/Search'));
const ManufacturerProfile = lazy(
  () => import('@/modules/user-management/manufacturer-profile'),
);
const MyMachinery = lazy(() => import('@/modules/machinery/my-machinery'));
const MySampleParts = lazy(
  () => import('@/modules/sample-parts/my-sample-parts'),
);
const MyManufacturerListsPage = lazy(
  () => import('@/modules/manufacturers/my-manufacturer-lists-page'),
);
const ManufacturerListDetailPage = lazy(
  () => import('@/modules/manufacturers/manufacturer-list-detail-page'),
);
const Checkout = lazy(() => import('./modules/payment/checkout'));
const RFQCreationPage = lazy(
  () => import('./modules/rfq-creation/rfq-creation-page'),
);
const Pricing = lazy(() => import('./modules/user-management/Pricing'));
const SignUpPage = lazy(
  () => import('./modules/user-management/signup/signup-page'),
);
const WelcomePage = lazy(
  () => import('./modules/user-management/signup/welcome-page'),
);
const VerifyEmail = lazy(
  () => import('./modules/user-management/verify-email'),
);
const PendingEnrollment = lazy(
  () => import('./modules/user-management/pending-enrollment'),
);
const SignInPage = lazy(() => import('./modules/user-management/sign-in-page'));
const LegacyLogin = lazy(
  () => import('./modules/user-management/legacy-login'),
);
const Feed = lazy(() => import('./modules/feeds/feed'));
const GhostMode = lazy(() => import('./modules/ghost-mode/ghost-mode'));
const TechnologiesList = lazy(
  () => import('./modules/ghost-mode/technologies-list'),
);
const MaterialsList = lazy(() => import('./modules/ghost-mode/materials-list'));
const EnrollUser = lazy(() => import('@/modules/user-management/enroll-user'));
const ManufacturerPage = lazy(
  () => import('@/modules/manufacturers/manufacturer-page'),
);

const Loader = (): JSX.Element => <PageLoader />;

export const router = createBrowserRouter([
  {
    path: '/404',
    element: <ErrorPage code={404} />,
  },
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        loader: (): Response => {
          return redirect('/search');
        },
      },
      {
        path: 'change-email-confirmation',
        element: (
          <Suspense fallback={<Loader />}>
            <ChangeEmailConfirmation />
          </Suspense>
        ),
      },
      {
        path: 'verify-email-confirmation',
        element: (
          <Suspense fallback={<Loader />}>
            <VerifyEmailConfirmation />
          </Suspense>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <Suspense fallback={<Loader />}>
            <ResetPassword />
          </Suspense>
        ),
      },
      {
        path: 'notifications-settings',
        element: (
          <Suspense fallback={<Loader />}>
            <SettingNotificationsSettings />
          </Suspense>
        ),
      },

      {
        path: 'pricing',
        element: (
          <Suspense fallback={<Loader />}>
            <Pricing />
          </Suspense>
        ),
      },
      {
        path: '',
        element: <FeedProvider />,
        children: [
          {
            path: 'feed',
            element: (
              <Suspense fallback={<Loader />}>
                <Feed />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'registration',
        element: (
          <Suspense fallback={<Loader />}>
            <SignUpPage />
          </Suspense>
        ),
        children: [
          {
            path: ':registrationTypeChoosen',
            element: (
              <Suspense fallback={<Loader />}>
                <SignUpPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'welcome',
        element: (
          <Suspense fallback={<Loader />}>
            <WelcomePage />
          </Suspense>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <Suspense fallback={<Loader />}>
            <VerifyEmail />
          </Suspense>
        ),
      },
      {
        path: 'pending-enrollment',
        element: (
          <Suspense fallback={<Loader />}>
            <PendingEnrollment />
          </Suspense>
        ),
      },
      {
        path: 'login',
        element: (
          <Suspense fallback={<Loader />}>
            <SignInPage />
          </Suspense>
        ),
      },
      {
        path: 'legacy-login',
        element: (
          <Suspense fallback={<Loader />}>
            <LegacyLogin />
          </Suspense>
        ),
      },
      {
        path: '',
        element: <UserAuthenticatedContext />,
        children: [
          {
            path: '',
            element: (
              <AuthorizationGuard disallowedUserTypes={[User.type.BUYER]} />
            ),
            children: [
              {
                path: 'requests',
                element: (
                  <Suspense fallback={<Loader />}>
                    <FeaturedRequests />
                  </Suspense>
                ),
              },
              {
                path: 'manufacturer-profile',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Loader />}>
                        <ManufacturerProfile />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'my-machinery',
                    element: (
                      <Suspense fallback={<Loader />}>
                        <MyMachinery />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'my-sample-parts',
                    element: (
                      <Suspense fallback={<Loader />}>
                        <MySampleParts />
                      </Suspense>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'my-manufacturer',
            loader: (): Response => {
              return redirect('/my-manufacturer-lists');
            },
          },
          {
            path: 'my-manufacturer-lists',
            element: (
              <Suspense fallback={<Loader />}>
                <MyManufacturerListsPage />
              </Suspense>
            ),
          },
          {
            path: 'my-manufacturer-lists/:manufacturerListId',
            element: (
              <Suspense fallback={<Loader />}>
                <ManufacturerListDetailPage />
              </Suspense>
            ),
          },
          {
            path: 'settings',
            element: (
              <Suspense fallback={<Loader />}>
                <UserSettings />
              </Suspense>
            ),
            children: [
              {
                path: '',
                loader: (): Response => {
                  return redirect('/settings/profile');
                },
              },
              {
                path: 'profile',
                element: (
                  <Suspense fallback={<Loader />}>
                    <SettingProfile />
                  </Suspense>
                ),
              },
              {
                path: 'company',
                element: (
                  <Suspense fallback={<Loader />}>
                    <SettingCompany />
                  </Suspense>
                ),
              },
              {
                path: 'notifications',
                element: (
                  <Suspense fallback={<Loader />}>
                    <SettingNotifications />
                  </Suspense>
                ),
              },
              {
                path: 'security',
                element: (
                  <Suspense fallback={<Loader />}>
                    <SettingSecurity />
                  </Suspense>
                ),
              },
              {
                path: '',
                element: (
                  <AuthorizationGuard disallowedUserTypes={[User.type.BUYER]} />
                ),
                children: [
                  {
                    path: 'subscription',
                    element: (
                      <Suspense fallback={<Loader />}>
                        <SettingSubscription />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'team',
                element: (
                  <Suspense fallback={<Loader />}>
                    <SettingTeam />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'enroll-user/:userId',
            element: (
              <Suspense fallback={<Loader />}>
                <EnrollUser />
              </Suspense>
            ),
          },
          {
            path: '',
            element: <CheckoutContextProvider />,
            children: [
              {
                path: 'checkout',
                element: (
                  <Suspense fallback={<Loader />}>
                    <Checkout />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'checkout-success',
            element: (
              <Suspense fallback={<Loader />}>
                <WelcomePage />
              </Suspense>
            ),
          },
          {
            path: 'manufacturers/:uuid',
            element: (
              <Suspense fallback={<Loader />}>
                <ManufacturerPage />
              </Suspense>
            ),
          },
          {
            path: 'project-not-found',
            element: (
              <Suspense fallback={<Loader />}>
                <ProjectNotFound />
              </Suspense>
            ),
          },
          {
            path: 'project/:projectId/invitations',
            element: (
              <Suspense fallback={<Loader />}>
                <ProjectInvitations />
              </Suspense>
            ),
          },
          {
            path: '',
            element: <RFQContextProvider />,
            children: [
              {
                path: 'request/:projectId/view',
                loader: ({ params }): Response => {
                  return redirect(`/project/${params.projectId}/view`);
                },
              },
              {
                path: 'project/:projectId/view',
                element: (
                  <Suspense fallback={<Loader />}>
                    <RFQSummary />
                  </Suspense>
                ),
              },
              {
                path: 'project/:projectId/edit',
                element: (
                  <Suspense fallback={<Loader />}>
                    <RFQEditPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'admin',
            element: (
              <AuthorizationGuard disallowedUserRoles={[User.role._1]} />
            ),
            children: [
              {
                path: 'ghost-mode',
                element: (
                  <Suspense fallback={<Loader />}>
                    <GhostMode />
                  </Suspense>
                ),
              },
              {
                path: 'technologies-list',
                element: (
                  <Suspense fallback={<Loader />}>
                    <TechnologiesList />
                  </Suspense>
                ),
              },
              {
                path: 'materials-list',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MaterialsList />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '',
        element: <RFQContextProvider />,
        children: [
          {
            path: 'request/public/:invitationToken',
            element: (
              <Suspense fallback={<Loader />}>
                <RFQSummary />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: '',
        element: <RFQCreationProvider />,
        children: [
          {
            path: '',
            element: <FileDropProvider />,
            children: [
              {
                path: 'search/*',
                element: (
                  <SearchProvider>
                    <Suspense fallback={<Loader />}>
                      <Search />
                    </Suspense>
                  </SearchProvider>
                ),
              },
            ],
          },
          {
            path: '',
            element: <UserAuthenticatedContext />,
            children: [
              {
                path: 'create-request',
                element: (
                  <Suspense fallback={<Loader />}>
                    <RFQCreationPage />
                  </Suspense>
                ),
              },
              {
                path: 'projects',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MyProjects />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);
