/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';
import type { Languages } from './Languages';
import type { ProjectInvitation } from './ProjectInvitation';
import type { ProjectPart } from './ProjectPart';

export type FeedElementDto = {
  projectId: number;
  companyId: number;
  /**
   * The name of the buyer company, omitted if the user is not allowed to see it
   */
  companyName: string | null;
  /**
   * The url of the logo of the company. Can be empty if user is not allowed to see it or if company simply does not have it
   */
  companyLogo: string | null;
  /**
   * Company country (i.e. FR, DE...)
   */
  companyCountry: string | null;
  title: string;
  deadline: string;
  deliveryDate: string | null;
  createdAt: string;
  updatedAt: string;
  /**
   *
   * This flag will be true if the user is (TIER 1 or higher) OR (if the user is invited and the RFQ is not locked).
   * When the flag is true, the user will be able to see full details of the RFQ in the feed (such us the full thumbnail, company name, etc.)
   * and performs actions without opening the Paywall modal, including:
   * - Request details
   * - Feedback actions (interested, not interested)
   * - See contact details
   * - NDA request status
   */
  hasUserFullAccessToRFQInFeed: boolean;
  totalQuantity: number;
  isNdaProtected: boolean;
  ndaFile: File | null;
  parts: Array<ProjectPart>;
  /**
   * The country codes of the tender area of the project
   */
  tenderArea: Array<string> | null;
  /**
   * The status of the project
   */
  status: FeedElementDto.status;
  languages: Array<Languages>;
  incoterms: FeedElementDto.incoterms | null;
  certifications: Array<string>;
  /**
   * Why buyer is creating the project (enum).
   */
  reasonForRequest: FeedElementDto.reasonForRequest;
  invitation: ProjectInvitation | null;
};

export namespace FeedElementDto {
  /**
   * The status of the project
   */
  export enum status {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
  }

  export enum incoterms {
    CFR = 'CFR',
    CIF = 'CIF',
    CIP = 'CIP',
    CPT = 'CPT',
    DAP = 'DAP',
    DAT = 'DAT',
    DDP = 'DDP',
    DPU = 'DPU',
    EXW = 'EXW',
    FAS = 'FAS',
    FCA = 'FCA',
    FOB = 'FOB',
  }

  /**
   * Why buyer is creating the project (enum).
   */
  export enum reasonForRequest {
    BENCHMARKING = 'BENCHMARKING',
    NEW_PRODUCT = 'NEW_PRODUCT',
    SECOND_SOURCE = 'SECOND_SOURCE',
    SUPPLIER_FAILURE = 'SUPPLIER_FAILURE',
  }
}
