/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { File } from './File';
import type { Material } from './Material';
import type { OnboardingStep } from './OnboardingStep';
import type { Technology } from './Technology';

export type Company = {
  id: number;
  created_at: string;
  updated_at: string;
  uuid: string;
  name: string;
  publicEmail: string;
  publicPhone: string | null;
  vat: string | null;
  website: string | null;
  stripeCustomerId: string | null;
  /**
   * The membership plan of a company
   */
  membershipPlan: Company.membershipPlan | null;
  /**
   * This field is nullable. When not null, means the company has no capacity until the given date
   */
  hasNoCapacityUntil: string | null;
  address: Address | null;
  billingAddress: Address | null;
  logo: File | null;
  showLogo: boolean;
  promotionalPdf: File | null;
  showPdf: boolean;
  /**
   * The youtube url of the video
   */
  promotionalVideo: string | null;
  showVideo: boolean;
  /**
   * Populated only when invitations are fetched
   */
  invitationsCount: number | null;
  onboardingStatus: Array<OnboardingStep> | null;
  description: string;
  promotionalImages: Array<File>;
  technologies: Array<Technology>;
  materials: Array<Material>;
  /**
   * Will be undefined when the user is not fetched
   */
  contactPersonName: string | null;
  /**
   * Computed field which tells if a company has capacity or not
   */
  hasCapacity: boolean;
};

export namespace Company {
  /**
   * The membership plan of a company
   */
  export enum membershipPlan {
    TIER0 = 'TIER0',
    TIER1 = 'TIER1',
    TIER2 = 'TIER2',
    TIER3 = 'TIER3',
  }
}
