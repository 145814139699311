/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { File } from './File';
import type { Languages } from './Languages';
import type { ProjectInvitation } from './ProjectInvitation';
import type { ProjectMatchedManufacturer } from './ProjectMatchedManufacturer';
import type { ProjectPart } from './ProjectPart';

export type Project = {
  id: number;
  created_at: string;
  updated_at: string;
  title: string;
  incoterms: Project.incoterms | null;
  currency: Project.currency | null;
  /**
   * External Id of the Project (optional)
   */
  projectId: string | null;
  deadline: string;
  deliveryDate: string | null;
  /**
   * Is the file protected by NDA. Boolean.
   */
  isNdaProtected: boolean;
  /**
   * Is the file private and not present in the feed. Boolean.
   */
  isPrivate: boolean;
  /**
   * Is the RFQ created by an ADMIN on behalf of a Company. Boolean.
   */
  isOnBehalf: boolean;
  note: string | null;
  /**
   * The reason why buyer is creating project. This is an enum.
   */
  reasonForRequest: Project.reasonForRequest;
  languages: Array<Languages>;
  /**
   * Can be either ACTIVE or CLOSED
   */
  status: Project.status;
  /**
   * The id of the company that created the project
   */
  companyId: number;
  /**
   * The id of the user that created the project
   */
  userId: number | null;
  additionalFiles: Array<File>;
  parts: Array<ProjectPart>;
  invitations: Array<ProjectInvitation>;
  /**
   * Certifications required to take on this project
   */
  certifications: Array<string>;
  /**
   * Tender area for filtering manufacturers by location
   */
  tenderArea: Array<string>;
  zipFile: File | null;
  ndaFile: File | null;
  isSniping?: boolean;
  lastInvitationBatchAt?: string | null;
  lastUserUpdateAt?: string | null;
  /**
   * Certifications required to take on this project
   */
  matchedManufacturers?: Array<ProjectMatchedManufacturer>;
  daysToDeadline?: number;
};

export namespace Project {
  export enum incoterms {
    CFR = 'CFR',
    CIF = 'CIF',
    CIP = 'CIP',
    CPT = 'CPT',
    DAP = 'DAP',
    DAT = 'DAT',
    DDP = 'DDP',
    DPU = 'DPU',
    EXW = 'EXW',
    FAS = 'FAS',
    FCA = 'FCA',
    FOB = 'FOB',
  }

  export enum currency {
    PKR = 'PKR',
    TZS = 'TZS',
    BTN = 'BTN',
    CRC = 'CRC',
    CZK = 'CZK',
    ERN = 'ERN',
    PEN = 'PEN',
    SAR = 'SAR',
    SBD = 'SBD',
    SCR = 'SCR',
    SYP = 'SYP',
    STD = 'STD',
    BGN = 'BGN',
    USD = 'USD',
    BMD = 'BMD',
    AFN = 'AFN',
    BYN = 'BYN',
    COP = 'COP',
    ANG = 'ANG',
    INR = 'INR',
    KRW = 'KRW',
    RWF = 'RWF',
    SOS = 'SOS',
    SVC = 'SVC',
    VUV = 'VUV',
    XCD = 'XCD',
    ZAR = 'ZAR',
    AOA = 'AOA',
    HNL = 'HNL',
    KPW = 'KPW',
    AWG = 'AWG',
    MXN = 'MXN',
    TTD = 'TTD',
    XPF = 'XPF',
    ZWL = 'ZWL',
    GGP = 'GGP',
    BZD = 'BZD',
    CNY = 'CNY',
    EGP = 'EGP',
    KES = 'KES',
    LYD = 'LYD',
    LRD = 'LRD',
    MUR = 'MUR',
    BDT = 'BDT',
    SRD = 'SRD',
    ALL = 'ALL',
    CLF = 'CLF',
    GYD = 'GYD',
    JPY = 'JPY',
    LKR = 'LKR',
    GBP = 'GBP',
    HUF = 'HUF',
    IDR = 'IDR',
    TJS = 'TJS',
    XOF = 'XOF',
    BRL = 'BRL',
    CUC = 'CUC',
    DKK = 'DKK',
    FKP = 'FKP',
    MAD = 'MAD',
    MDL = 'MDL',
    MGA = 'MGA',
    MOP = 'MOP',
    MRO = 'MRO',
    NAD = 'NAD',
    NPR = 'NPR',
    NZD = 'NZD',
    PAB = 'PAB',
    UZS = 'UZS',
    IRR = 'IRR',
    ARS = 'ARS',
    JOD = 'JOD',
    MWK = 'MWK',
    BBD = 'BBD',
    SEK = 'SEK',
    CHE = 'CHE',
    CHF = 'CHF',
    DZD = 'DZD',
    GIP = 'GIP',
    GNF = 'GNF',
    HRK = 'HRK',
    LBP = 'LBP',
    SZL = 'SZL',
    VEF = 'VEF',
    XAF = 'XAF',
    DJF = 'DJF',
    FJD = 'FJD',
    GEL = 'GEL',
    AED = 'AED',
    MYR = 'MYR',
    MZN = 'MZN',
    QAR = 'QAR',
    RUB = 'RUB',
    SDG = 'SDG',
    TMT = 'TMT',
    TRY = 'TRY',
    CVE = 'CVE',
    DOP = 'DOP',
    GMD = 'GMD',
    MNT = 'MNT',
    PHP = 'PHP',
    UAH = 'UAH',
    VND = 'VND',
    BND = 'BND',
    BSD = 'BSD',
    GHS = 'GHS',
    KGS = 'KGS',
    KWD = 'KWD',
    MMK = 'MMK',
    NGN = 'NGN',
    NIO = 'NIO',
    RSD = 'RSD',
    SGD = 'SGD',
    SLL = 'SLL',
    THB = 'THB',
    TND = 'TND',
    BHD = 'BHD',
    YER = 'YER',
    ZMW = 'ZMW',
    EUR = 'EUR',
    ISK = 'ISK',
    MVR = 'MVR',
    AZN = 'AZN',
    NOK = 'NOK',
    SHP = 'SHP',
    UGX = 'UGX',
    AMD = 'AMD',
    HKD = 'HKD',
    HTG = 'HTG',
    OMR = 'OMR',
    BAM = 'BAM',
    PGK = 'PGK',
    TOP = 'TOP',
    TWD = 'TWD',
    BOB = 'BOB',
    CDF = 'CDF',
    ILS = 'ILS',
    IQD = 'IQD',
    KHR = 'KHR',
    KMF = 'KMF',
    AUD = 'AUD',
    LAK = 'LAK',
    LSL = 'LSL',
    MKD = 'MKD',
    PLN = 'PLN',
    PYG = 'PYG',
    RON = 'RON',
    UYI = 'UYI',
    WST = 'WST',
    BIF = 'BIF',
    BWP = 'BWP',
    CAD = 'CAD',
    GTQ = 'GTQ',
    JMD = 'JMD',
    KYD = 'KYD',
    KZT = 'KZT',
  }

  /**
   * The reason why buyer is creating project. This is an enum.
   */
  export enum reasonForRequest {
    BENCHMARKING = 'BENCHMARKING',
    NEW_PRODUCT = 'NEW_PRODUCT',
    SECOND_SOURCE = 'SECOND_SOURCE',
    SUPPLIER_FAILURE = 'SUPPLIER_FAILURE',
  }

  /**
   * Can be either ACTIVE or CLOSED
   */
  export enum status {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
  }
}
