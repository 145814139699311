/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useState } from 'react';

import { FreeplanReachedBanner } from '@/core/components/layout/freeplan-reached-banner';
import { SideNav } from '@/core/components/layout/sidenav';
import { TopNav } from '@/core/components/layout/topnav';
import { Toaster } from '@/core/components/ui/toaster';
import { useAuthenticationContext } from '@/core/context/AuthenticationContext';
import { Breakpoints, useBreakpoint } from '@/core/context/BreakpointContext';
import { useInvitationsThisMonth } from '@/modules/user-management/services/queries';
import { User } from '@/generated/api';

export interface LayoutProps {
  children: ReactNode;
  hideNavigationBar?: boolean;
  className?: string;
}

const LayoutContext = createContext({
  hideNavigationBar: () => {},
  showNavigationBar: () => {},
  expanded: false,
});

type LayoutContextProps = {
  hideNavigationBar: () => void;
  showNavigationBar: () => void;
  expanded: boolean;
};

export function useLayoutContext(): LayoutContextProps {
  return useContext(LayoutContext);
}

export default function Layout({ children }: LayoutProps): JSX.Element {
  const { breakpoint } = useBreakpoint();
  const { isLoggedIn, currentUser } = useAuthenticationContext();
  const [navbarExpanded, setNavbarExpanded] = useState<boolean>(false);
  const [navigationVisible, setNavigationVisible] = useState<boolean>(true);
  const { data: nbInvitationsThisMonth } = useInvitationsThisMonth(isLoggedIn);

  return (
    <div className="flex min-h-screen bg-white lg:block">
      <LayoutContext.Provider
        value={{
          hideNavigationBar: () => setNavigationVisible(false),
          showNavigationBar: () => setNavigationVisible(true),
          expanded: navbarExpanded,
        }}
      >
        {navigationVisible && (
          <>
            <SideNav expanded={navbarExpanded} onExpanded={setNavbarExpanded} />
            <TopNav
              expanded={navbarExpanded && breakpoint <= Breakpoints.LG}
              onExpanded={setNavbarExpanded}
            />
          </>
        )}
        <main data-testid="layout__main-content" className="grow">
          {isLoggedIn &&
            currentUser?.company.membershipPlan === null &&
            Number(nbInvitationsThisMonth) > 0 &&
            currentUser?.type !== User.type.BUYER && <FreeplanReachedBanner />}
          {children}
        </main>
        <Toaster />
      </LayoutContext.Provider>
    </div>
  );
}
